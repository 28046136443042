<template>
  <div>
    <div class="f1 w-1400px">
      <div class="title">联系我们</div>
    </div>
    <div class="w-1400px">
      <p class="contactText"  data-aos="slide-up">联系在赢端，让企业的每一次营销投入都变成企业对用户的“有效投资”</p>
      <div class="contactInfo"  data-aos="slide-up">  
        <p>公司总机：021-67869265</p>
        <p>商务邮箱：service@kuailiwu.com</p>
      </div>
    </div>
    <div class="map" data-aos="slide-up">
      <img class="coordinate" src="../../assets/Cooperation/coordinate.png" alt="">
    </div>
    <div class="f3 w-1400px" data-aos="slide-up">
      <div class="title">与在赢端携手共赢</div>
    </div>
    <div class="submitInfo">
      <ul data-aos="fade-right">
        <li>
          <input v-model="name" type="text" placeholder="您的姓名">
          <span v-if="verification.name">*请输入姓名</span>
         </li>
        <li>
          <input v-model="phone" type="text" placeholder="手机号码" maxlength="11">
          <span v-if="verification.phone">*请输入正确的手机号</span>
        </li>
        <li>
          <input v-model="mail" type="text" placeholder="您的邮箱">
          <span v-if="verification.mail">*请输入正确的邮箱</span>
        </li>
      </ul>
      <div class="textAreas">
        <textarea v-model="content" data-aos="fade-left" name="" id="" cols="30" rows="10" placeholder="请填写您的需求描述" maxlength="250"></textarea>
        <span v-if="verification.content">*请输入需求描述</span>
      </div>      
    </div>
    <div class="btn-submit" data-aos="slide-up" @click="submitFun">{{submitText}}</div>
  </div>
</template>

<script>
  import jsonp from 'jsonp'
  const phoneReg = /^(?:(?:\+|00)86)?1\d{10}$/;
  const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  export default {
    data() {
      return {
        name:"",
        phone:"",
        mail:"",
        content:"",
        verification:{
          name:false,
          phone:false,
          mail:false,
          content:false,
        },
        submitFlag:true,
        submitText:"提交",
      }
    },
    watch: {
      name(newValue, oldValue) {
        newValue.length>1?this.verification.name = false:''
      },
      phone(newValue, oldValue) {
        phoneReg.test(newValue)?this.verification.phone = false:''
      },
      mail(newValue, oldValue) {
        mailReg.test(newValue)?this.verification.mail = false:''
      },
      content(newValue, oldValue) {
        newValue.length>10?this.verification.content = false:''
      },
    },
    methods: {
      submitFun() {
        if(this.name==""){
          this.verification.name = true
          return
        }
        if(!phoneReg.test(this.phone)){
          this.verification.phone = true
          return
        }
        if(!mailReg.test(this.mail)){
          this.verification.mail = true
          return
        }
        if(this.content==""){
          this.verification.content = true
          return
        }
        if(!this.submitFlag){
          return
        }
        this.submitFlag = false;
        this.submitText = "提交成功";
        jsonp(`https://reportmobile.kuailiwu.com/Monitor/Data/Asmkt-Web-Tb/Save?Name=1&UserName=${this.name}&UserMail=${this.mail}&UserTell=${this.phone}&UserInfo=${this.content}`, null, (err, data) => {
          if (err) {
            this.submitText = "提交失败";
          } else {
            this.submitText = "提交成功";
          }
        })
      }
    },
  }
</script>

<style src="./Cooperation.scss" lang="scss" scoped></style>